import { FormattedMessage } from 'react-intl';
import Document5 from '@/common/documents/politika-konfidecialnosti-i-obrabotka-personalnyh-dannyh.pdf';
import Document6 from '@/common/documents/pravila-predostavleniya-mikrokreditov-ehlektronnym-sposobom.pdf';
import Document7 from '@/common/documents/pravila-uregulirovaniya-prosrochennoj-zadolzhennosti.pdf';
import Document8 from '@/common/documents/procedura-bezopasnosti-i-zashchity-informacii.pdf';
import Document10 from '@/common/documents/soglasie-subekta-kreditnoj-istorii-na-predstavlenie-svedenij.pdf';
import Document2 from '@/common/documents/Договор_о_предоставлении_микрокредита_CreditNova.pdf';
import Document9 from '@/common/documents/Заявление_на_страхование.pdf';
import Document11 from '@/common/documents/Заявление_о_присоединении_к_договору_CreditNova.pdf';
import Document4 from '@/common/documents/Лицензия.pdf';
import Document12 from '@/common/documents/Правила_добровольного_онлайн_страхования_жизни_СД_от_150822г_с.pdf';
import Document13 from '@/common/documents/Правила_добровольного_онлайн_страхования_от_НС_СД_от_050321г_с.pdf';
import Document1 from '@/common/documents/Согласие_на_сбор_и_обработку_персональных_данных_CreditNova.pdf';

export const documentsList = [
  {
    title: (
      <FormattedMessage defaultMessage="Договор о предоставлении микрокредита" />
    ),
    link: Document2,
  },
  {
    title: (
      <FormattedMessage defaultMessage="Заявление о присоединении к договору о предоставлении микрокредита" />
    ),
    link: Document11,
  },
  {
    title: <FormattedMessage defaultMessage="Заявление на страхование" />,
    link: Document9,
  },
  {
    title: (
      <FormattedMessage defaultMessage="Правила предоставления микрокредитов электронным способом" />
    ),
    link: Document6,
  },
  {
    title: (
      <FormattedMessage defaultMessage="Правила добровольного онлайн страхования жизни" />
    ),
    link: Document12,
  },
  {
    title: (
      <FormattedMessage defaultMessage="Правила добровольного онлайн страхования от несчастного случая" />
    ),
    link: Document13,
  },
  {
    title: (
      <FormattedMessage defaultMessage="Правила урегулирования просроченной задолженности" />
    ),
    link: Document7,
  },
  {
    title: (
      <FormattedMessage defaultMessage="Процедура безопасности и защиты информации" />
    ),
    link: Document8,
  },
  {
    title: (
      <FormattedMessage defaultMessage="Политика конфидециальности и обработка персональных данных" />
    ),
    link: Document5,
  },
  {
    title: (
      <FormattedMessage defaultMessage="Согласие на сбор и обработку персональных данных" />
    ),
    link: Document1,
  },
  {
    title: (
      <FormattedMessage defaultMessage="Согласие субъекта кредитной истории на представление сведений" />
    ),
    link: Document10,
  },
  {
    title: <FormattedMessage defaultMessage="Лицензия" />,
    link: Document4,
  },
];
